/**
 * Custom cell render functions.
 *
 * @param {Object} instance - handsontable instance
 * @param {Object} td - html cell object
 * @param {Number} row - visual row index
 * @param {Number} col - visual column index
 * @param {String} prop - cell data accessor (maps to column `data` attribute)
 * @param {String} value - cell value
 * @param {Object} cellProperties - Handsontable.ColumnSettings object (metadata)
 *
 * https://handsontable.com/docs/7.1.0/demo-custom-renderers.html
 */
import Handsontable from 'handsontable'
import router from '@/router'

const renderers = {
  /**
   * Enhanced text renderer. Adds:
   *  - wrap cell content in a <div>
   *  - tooltip for invalid cells (TODO: add error info)
   */
  TextRenderer: function (instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments)

    if (value) {
      const div = document.createElement('div')
      div.textContent = value

      if (cellProperties.isValid === false) {
        div.title = 'Invalid data'
      }
      td.firstChild.remove()
      td.appendChild(div)
    }
  },
  /**
   * Renders cell content as preformatted JSON.
   */
  JSONRenderer: function (instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments)

    const pre = document.createElement('pre')
    try {
      pre.textContent = JSON.stringify(JSON.parse(value), null, 2)
    } catch (err) {
      pre.textContent = value
    }
    td.firstChild.remove()
    td.appendChild(pre)
  },
  ListRenderer: function (instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments)

    if (Array.isArray(value) && value.length) {
      td.textContent = value.join(', ')
    }
  },
  /**
   * Renders cell content as an html link element.
   */
  URLRenderer: function (instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments)

    if (value) {
      const a = document.createElement('a')
      a.href = value
      a.target = '_blank'
      a.textContent = value
      td.firstChild.remove()
      td.appendChild(a)
    }
  },
  /**
   * Renders a comma-separated list of URLs as html link elements.
   */
  URLListRenderer: function (instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments)

    while (td.firstChild) {
      td.firstChild.remove()
    }

    // Filter out empty values
    const urls = value.filter(el => el)

    for (const url of urls) {
      const div = document.createElement('div')
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      a.textContent = url
      div.appendChild(a)
      td.appendChild(div)
    }
  },
  /**
   * Highlights text based on the cell value.
   */
  StatusRenderer: function (instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments)

    // vue-handsontable doesn't support vue components
    // in renderer's (yet) , so this leverages the global
    // css classes applied by element-ui to make cell
    // content appear as if it was a native ui component

    const span = document.createElement('div')
    span.textContent = value

    switch (value) {
      case 'active':
        span.classList.add('el-tag--success')
        break
      case 'inactive':
        span.classList.add('el-tag--info')
        break
      case 'offboarding':
        span.classList.add('el-tag--danger')
        break
      case 'onboarding':
        span.classList.add('el-tag--warning')
        break
      case 'trial':
        span.classList.add('el-tag--primary')
        break
    }

    // Add a little style (DO NOT use border or padding, it will
    // mess up row alignment with fixed columns on high dpi displays)
    span.style.borderRadius = '8px'

    td.style.verticalAlign = 'middle'
    td.style.textAlign = 'center'
    td.firstChild.remove()
    td.appendChild(span)
  },
  /**
   * Renders an html edit button in the given cell.
   */
  EditButtonRenderer: function (instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.HtmlRenderer.apply(this, arguments)

    const data = instance.getSourceData()
    const index = instance.toPhysicalRow(row)
    const propertyObj = data[index]

    const div = document.createElement('div')
    div.textContent = 'Edit'

    div.classList.add('el-button--text')
    div.style.fontWeight = 'bold'
    div.style.cursor = 'pointer'
    div.style.textDecoration = 'underline'

    td.onclick = () => router.push({ name: 'PropertyEdit', params: { id: propertyObj.property.id } })
    td.style.verticalAlign = 'middle'
    td.style.textAlign = 'center'
    td.appendChild(div)
  },
  /**
   * Renders cell value as a link to the property edit view.
   */
  EditLinkRenderer: function (instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.HtmlRenderer.apply(this, arguments)

    const data = instance.getSourceData()
    const index = instance.toPhysicalRow(row)
    const propertyObj = data[index]

    const div = document.createElement('div')
    div.textContent = value

    div.classList.add('el-button--text')
    div.style.display = 'inline-block'
    div.style.cursor = 'pointer'
    div.style.textDecoration = 'underline'
    div.onclick = () => router.push({ name: 'PropertyEdit', params: { id: propertyObj.property.id } })

    td.firstChild.remove()
    td.appendChild(div)
  }
}

export default renderers
