/**
 * staleDataCheckMixin.js
 *
 * A mixin for components that require checking for
 * modified source data before allowing a resource
 * to be updated.
 *
 * **!!** IMPORTANT **!!**
 * In order for this mixin to work properly, the calling
 * component MUST implement the `isSynced` and `refresh`
 * methods.
 *
 * isSynced: function to be invoked by the interval timer
 * refresh: callback function for refresh button click
 */
import IntervalTimer from '@/utils/interval'

const staleDataCheckMixin = {
  created () {
    // Check for required methods on the component
    if (!this.isSynced) {
      console.warn('staleDataCheckMixin: method `isSynced` must be defined on the component')
    }
    if (!this.refresh) {
      console.warn('staleDataCheckMixin: method `refresh` must be defined on the component')
    }
    this.message = null

    const syncFunction = async () => {
      const synced = await this.isSynced()

      if (!synced && !this.message) {
        this.$_staleDataCheckMixin_openMessage()
      }
    }

    const intervalDelay = 2 * 60 * 1000 // 2 min
    this.timer = new IntervalTimer(syncFunction, intervalDelay)
    this.timer.start()
  },
  beforeDestroy () {
    this.$_staleDataCheckMixin_closeMessage()
    this.timer.stop()
  },
  methods: {
    /**
     * Display a warning message to the user informing them
     * that the data they are viewing is outdated.
     */
    $_staleDataCheckMixin_openMessage () {
      const h = this.$createElement
      this.message = this.$message({
        message: h('p', null, [
          h('span', { style: { fontSize: '14px' } }, 'Data on this page is out of date'),
          h('el-button', {
            props: { type: 'warning', size: 'small' },
            style: { fontSize: '14px', marginLeft: '20px' },
            on: { click: this.refresh }
          }, 'Refresh')
        ]),
        type: 'warning',
        duration: 0,
        showClose: false,
        onClose: () => { this.message = null }
      })
    },
    /**
     * Close the Message element, if one exists.
     */
    $_staleDataCheckMixin_closeMessage () {
      if (this.message) {
        this.message.close()
      }
    },
    /**
     * Opens a confirmation MessageBox element informing
     * the user that the update they would like to perform
     * may have unintended consequences if they continue.
     *
     * @returns {Boolean} - true if user wants to update (unsafe), else false
     */
    async $_staleDataCheckMixin_openMessageBox () {
      // Hide warning message and turn off timer to prevent it from
      // popping up while the MessageBox element is being displayed
      this.$_staleDataCheckMixin_closeMessage()
      this.timer.stop()

      try {
        await this.$confirm('This update may overwrite existing fields. It\'s recommended ' +
                            'that you record your edits and refresh the data before continuing.', {
          title: 'Source Data Changed',
          type: 'error',
          confirmButtonText: 'I understand the consequences, continue',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'el-button--danger',
          cancelButtonClass: 'el-button--primary',
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false
        })
        return true
      } catch (err) {
        // User cancelled, display the warning as a reminder and restart timer
        this.$_staleDataCheckMixin_openMessage()
        this.timer.start()
      }
      return false
    }
  }
}

export default staleDataCheckMixin
