/**
 * Custom cell types.
 *
 * A cell type is a predefined set of properties for a cell. The editor,
 * renderer, and (optionally) validator functions should be specified, in
 * addition to any custom cell properties.
 *
 * https://handsontable.com/docs/7.1.0/tutorial-cell-types.html
 */
import Handsontable from 'handsontable'

import editors from './editors'
import renderers from './renderers'
import validators from './validators'

const cellTypes = {
  text: {
    editor: Handsontable.editors.TextEditor,
    renderer: renderers.TextRenderer
  },
  json: {
    editor: editors.JSONEditor,
    renderer: renderers.JSONRenderer,
    validator: validators.JSONValidator
  },
  list: {
    editor: editors.ListEditor,
    renderer: renderers.ListRenderer
  },
  url: {
    editor: Handsontable.editors.TextEditor,
    renderer: renderers.URLRenderer,
    validator: validators.URLValidator
  },
  urlList: {
    editor: editors.ListEditor,
    renderer: renderers.URLListRenderer,
    validator: validators.URLListValidator
  }
}

export default cellTypes
