<template>
  <div id="property-spreadsheet-table">
    <div class="property-count">
      Total: <b>{{ loading ? 'loading...' : settings.data.length }}</b>
    </div>

    <div v-if="loading || settings.data.length > 0">
      <div class="row">
        <div class="col">
          <div class="sheet-select">
            <el-select
              v-model="selectedSpreadsheet"
              size="small"
              @change="onSpreadsheetChange"
            >
              <el-option
                v-for="option in spreadsheetOptions"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </div>
        </div>
        <div class="col">
          <div class="filter">
            <el-input
              v-model="filter"
              placeholder="Search"
              clearable
            />
          </div>
        </div>
      </div>

      <div class="controls">
        <el-button
          type="success"
          size="mini"
          @click="save()"
        >
          Save
        </el-button>
        <el-button
          type="primary"
          size="mini"
          @click="refresh()"
        >
          Refresh
        </el-button>
        <span class="message" :class="{ 'error': error }">
          {{ info }}
        </span>
        <span class="message">
          {{ unsavedChanges > 0 ? `${unsavedChanges} unsaved changes.` : 'All changes saved.' }}
        </span>
      </div>

      <property-spreadsheet
        ref="properties"
        :loading="loading"
        :data="filteredData"
        :on-change="handleChange"
        :col-headers="settings.colHeaders"
        :columns="settings.columns"
        :fixed-columns-left="1"
      />

      <batch-operation
        ref="batchUpdate"
        :items="propertiesToUpdate"
        :operation="updateProperty"
        :callback="batchUpdateCallback"
        data-type="requests"
        value-key="property.name"
        progress-message="Updating properties..."
      />
    </div>

    <div v-else class="no-data-message">
      It doesn't look like there's any data to display! If you'd like, you can add a property
      <link-button
        :to="{ name: 'PropertyAdd' }"
        type="primary"
      >
        here
      </link-button>.
    </div>
  </div>
</template>

<script>
import Spreadsheet from '@/components/spreadsheet/Spreadsheet'
import BatchOperation from '@/components/batch/BatchOperation'
import staleDataCheckMixin from '@/mixins/staleDataCheckMixin'
import LinkButton from '@/components/buttons/LinkButton'
import CraigslistAPI from '@/services/api/craigslist'
import RooofAPI from '@/services/api/rooof'
import handsontable from '@/utils/handsontable'
import { isEmptyAddress, isValidAddress } from '@/utils/rooof'

export default {
  name: 'PropertySpreadsheetTable',
  components: {
    'property-spreadsheet': Spreadsheet,
    'batch-operation': BatchOperation,
    'link-button': LinkButton
  },
  mixins: [
    staleDataCheckMixin
  ],
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      settings: {
        data: [],
        colHeaders: [],
        columns: []
      },
      spreadsheetOptions: handsontable.getPropertyTablePresets(),
      selectedSpreadsheet: 'general',
      lastModified: null,
      loading: false,
      error: false,
      info: '',
      filter: '',
      propertiesToUpdate: []
    }
  },
  computed: {
    unsavedChanges () {
      const changed = this.settings.data.filter(prop => prop.isDirty === true)
      return changed.length
    },
    filteredData () {
      if (!this.filter) {
        return this.settings.data
      }
      return this.settings.data.filter(prop => {
        return prop.property.name.toLowerCase().includes(this.filter.toLowerCase())
      })
    }
  },
  mounted () {
    const settings = handsontable.getTableSettings(this.selectedSpreadsheet)
    this.settings.colHeaders = settings.colHeaders
    this.settings.columns = settings.columns
    this.getPropertyList()
  },
  methods: {
    /**
     * Determine if the current dataset is synced with the source.
     *
     * Used by the staleDataCheckMixin.
     *
     * @returns {Boolean} - true if data is up-to-date, else false
     */
    async isSynced () {
      try {
        const properties = await CraigslistAPI.properties.list({ group: this.company.name })
        const lastModified = this.getLastModifiedTimestamp(properties)
        return lastModified.getTime() === this.lastModified.getTime()
      } catch (err) {
        this.info = err.toString()
        this.error = true
      }
      return false
    },
    /**
     * Fetch property list from the API.
     */
    async getPropertyList () {
      this.info = 'Fetching properties...'
      this.error = false
      this.loading = true

      try {
        const properties = await CraigslistAPI.properties.list({ group: this.company.name })
        this.settings.data = properties.sort((a, b) => a.property.name.localeCompare(b.property.name))
        this.$refs['properties'].$refs['spreadsheet'].hotInstance.loadData(this.settings.data)
        this.lastModified = this.getLastModifiedTimestamp(this.settings.data)
        this.info = 'Property list up-to-date'
        this.$_staleDataCheckMixin_closeMessage()
      } catch (error) {
        this.info = error.toString()
        this.error = true
      }
      this.loading = false
    },
    /**
     * Get the most recent last_modified date of the given properties.
     *
     * @param {Array} properties - list of CraigslistProperty data
     * @returns {(Date|null)}
     */
    getLastModifiedTimestamp (properties) {
      const lastModifiedDates = properties.map(property => {
        return Math.max(new Date(property.last_modified), new Date(property.property.last_modified))
      })
      if (!lastModifiedDates.length) {
        return null
      }
      return new Date(lastModifiedDates.reduce((a, b) => Math.max(a, b)))
    },
    /**
     * Handsontable afterChange handler.
     *
     * @param {Array} changes - 2D array containing info about each of the edited cells
     * @param {String} source - identifies source of hook call
     */
    handleChange (changes, source) {
      if (source === 'loadData' || changes === null) {
        return
      }
      /* eslint-disable no-unused-vars */
      for (const [row, prop, oldValue, newValue] of changes) {
        if (oldValue === newValue) {
          continue
        }
        // Mark changed row as dirty
        const table = this.$refs['properties'].$refs['spreadsheet'].hotInstance
        const index = table.toPhysicalRow(row)
        const propertyData = this.settings.data[index]
        this.$set(propertyData, 'isDirty', true)
      }
    },
    /**
     * Validate the given address. If invalid address is found,
     * marks related cells as invalid using ht `valid` cell meta
     * attribute.
     *
     * @param {Object} table
     * @param {Object} property
     * @param {String} addresssType
     * @param {Number} index
     * @returns {Boolean} true if address was valid, else false
     */
    validateAddress (table, property, addressType, index) {
      const address = property[addressType]

      if (!isEmptyAddress(address)) {
        if (!isValidAddress(address)) {
          for (const prop of Object.keys(address)) {
            const visualRowIndex = table.toVisualRow(index)
            const visualColIndex = table.propToCol(`property.${addressType}.${prop}`)
            table.setCellMeta(visualRowIndex, visualColIndex, 'valid', false)
            table.render()
          }
          return false
        }
      }
      return true
    },
    /**
     * Validates all cells in the table. First checks each cell
     * is valid according to any handsontable validator functions,
     * then performs multi-column validation checks (eg. address fields).
     *
     * @returns {Promise}
     */
    validate () {
      const table = this.$refs['properties'].$refs['spreadsheet'].hotInstance

      return new Promise((resolve, reject) => {
        // Validate individual cells
        table.validateCells(valid => {
          if (!valid) {
            resolve(false)
          } else {
            // Iterate through the table rows, validating address fields.
            // If invalid addresses are found, mark those cells as invalid.
            let hasValidAddresses = true

            for (let i = 0; i < this.settings.data.length; i++) {
              const property = this.settings.data[i].property

              if (!this.validateAddress(table, property, 'billing_address', i)) {
                hasValidAddresses = false
              }
              if (!this.validateAddress(table, property, 'property_address', i)) {
                hasValidAddresses = false
              }
            }
            if (!hasValidAddresses) {
              resolve(false)
            }
          }
          return resolve(true)
        })
      })
    },
    /**
     * Creates update requests to the API, after checking
     * for validity and making sure the source data hasn't
     * changed since the last time it was fetched.
     */
    async save () {
      const valid = await this.validate()
      if (!valid) {
        this.error = true
        this.info = 'Invalid data'
        return
      }

      const promises = []

      // Fetch the latest property data for each of the dirty properties
      for (const propertyData of this.settings.data) {
        if (propertyData.isDirty) {
          this.propertiesToUpdate.push(propertyData)
          promises.push(CraigslistAPI.properties.retrieve(propertyData.property.id))
        }
      }

      try {
        this.info = 'Preparing for update...'
        const properties = await Promise.all(promises)

        // Make sure the timestamps haven't changed
        const currentTimestamp = this.getLastModifiedTimestamp(this.propertiesToUpdate)
        const lastModified = this.getLastModifiedTimestamp(properties)

        if (lastModified > currentTimestamp) {
          const shouldUpdateUnsafe = await this.$_staleDataCheckMixin_openMessageBox()
          if (!shouldUpdateUnsafe) {
            return
          }
        }
      } catch (err) {
        this.info = err.toString()
        this.error = true
      }

      this.info = 'Saving...'
      this.error = false
      this.$refs['batchUpdate'].start()
    },
    updateProperty (property) {
      // remove subscriptions from request since they are prone to API deadlocks
      delete property.property.feature_subscriptions
      delete property.property.product_subscriptions
      const promises = []
      promises.push(CraigslistAPI.properties.update(property.property.id, property))
      promises.push(RooofAPI.properties.update(property.property.id, property.property))
      return promises
    },
    batchUpdateCallback () {
      this.info = 'Saved!'
      this.refresh()
    },
    /**
     * Fetch the property list from the API.
     *
     * Used by the staleDataCheckMixin.
     */
    refresh () {
      this.getPropertyList()
    },
    /**
     * onChange handler for spreadsheet select control.
     *
     * @param {String} selectedValue - current selected value
     */
    onSpreadsheetChange (selectedValue) {
      if (this.unsavedChanges > 0) {
        alert('Please save your changes before moving to a new spreadsheet')
        return
      }
      const settings = handsontable.getTableSettings(selectedValue)
      this.settings.colHeaders = settings.colHeaders
      this.settings.columns = settings.columns

      // Trigger handsontable instance re-render to update row heights
      this.$nextTick(() => {
        this.$refs['properties'].$refs['spreadsheet'].hotInstance.render()
      })
    },
    /**
     * Display a temporary message to the user.
     *
     * @param {String} msg - the message to display
     */
    displayTempMessage (msg) {
      this.info = msg
      setTimeout(() => {
        this.info = ''
      }, 1500)
    }
  }
}
</script>

<style scoped>
.property-count {
  padding-bottom: 1em;
}
.row {
  display: flex;
  justify-content: space-between;
}
.error {
  color: red;
}
.controls {
  display: flex;
  margin-bottom: 1em;
}
.sheet-select {
  margin-bottom: 1em;
}
.filter {
  width: 300px;
}
.message {
  font-family: monospace;
  margin-left: 3em;
}
.message span {
  white-space: pre;
}
</style>
