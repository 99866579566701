/**
 * interval.js
 *
 * A stateful interval timer.
 */
export default class IntervalTimer {
  /**
   * @param {Function} fn - function to execute
   * @param {Number} delay - fixed time delay between each call to fn (ms)
   */
  constructor (fn, delay) {
    if (!fn || typeof fn !== 'function') {
      throw new Error('Missing required argument: fn')
    }
    if (!delay || typeof delay !== 'number') {
      throw new Error('Missing required argument: delay')
    }

    this.fn = fn
    this.delay = delay
    this.interval = null
    this.isRunning = false
  }

  /**
   * Start the interval timer.
   */
  start () {
    if (!this.isRunning) {
      this.interval = setInterval(this.fn, this.delay)
      this.isRunning = true
    }
  }

  /**
   * Stop the interval timer.
   */
  stop () {
    if (this.isRunning) {
      clearInterval(this.interval)
      this.isRunning = false
    }
  }
}
