/**
 * Custom cell validators.
 *
 * @param {*} value - the cell value to validate
 * @param {Function} callback - callback function (call with true if valid, else false)
 *
 * https://handsontable.com/docs/7.1.0/demo-data-validation.html
 */
import regex from '@/utils/regex'

/* eslint-disable standard/no-callback-literal */

const validators = {
  EmailValidator: function (email, callback) {
    return email ? callback(regex.email.test(email)) : callback(true)
  },
  URLValidator: function (url, callback) {
    return url ? callback(regex.url.test(url)) : callback(true)
  },
  URLListValidator: function (value, callback) {
    if (!value) {
      return callback(true)
    }
    const valid = value.every(url => regex.url.test(url))
    return callback(valid)
  },
  MaxLengthValidator: function (value, maxLength, callback) {
    return value ? callback(value.toString().length <= maxLength) : callback(true)
  },
  MaxValueValidator: function (value, maxValue, callback) {
    if (!value) {
      return callback(true)
    }
    if (typeof (value) !== 'number') {
      return callback(false)
    }
    return callback(value <= maxValue)
  },
  JSONValidator: function (value, callback) {
    try {
      JSON.parse(value)
      return callback(true)
    } catch (err) {
      return callback(false)
    }
  },
  IPAddressValidator: function (ip, callback) {
    return ip ? callback(regex.ip.test(ip)) : callback(true)
  }
}

export default validators
