<template>
  <div id="spreadsheet-container">
    <hot-table
      id="spreadsheet"
      ref="spreadsheet"
      v-loading="loading"
      :data="data"
      :data-schema="dataSchema"
      :col-headers="colHeaders"
      :columns="columns"
      :settings="settings"
      :after-change="onChange"
    />
  </div>
</template>

<script>
import { HotTable } from '@handsontable/vue'

/**
 * Spreadsheet.vue
 *
 * A base component that renders a Handsontable spreadsheet with
 * some sensible defaults. Any table settings you wish to be reactive
 * should be passed in as props and added to the <hot-table>
 * component directly so that changes can be observed.
 *
 * Handsontable settings: https://docs.handsontable.com/6.2.2/Options.html
 *
 * @prop {Array} props.data - table data source (array of objects)
 * @prop {Object} props.dataSchema - defines the structure of a new row (only needed if props.data is empty)
 * @prop {Function} props.onChange - callback function for afterChange event
 * @prop {Array} props.colHeaders - column header names as they appear in the spreadsheet
 * @prop {Array} props.columns - defines cell properties and data binding for each column
 * @prop {Number} [props.fixedColumnsLeft] - specify the number of frozen columns on the left of the table
 * @prop {Boolean} [props.loading] - display a loading overlay over the table
 */
export default {
  name: 'Spreadsheet',
  components: {
    'hot-table': HotTable
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    dataSchema: {
      type: Object,
      default: undefined
    },
    onChange: {
      type: Function,
      required: true
    },
    colHeaders: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    fixedColumnsLeft: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      settings: {
        licenseKey: '40f93-b5f7f-7ca92-2422a-6335a',
        rowHeaders: true,
        width: '100%',
        height: '50vh',
        search: {
          searchResultClass: 'search-highlight'
        },
        undo: true,
        comments: true,
        contextMenu: true,
        dropdownMenu: [
          'make_read_only',
          '---------',
          'filter_by_value',
          'filter_action_bar'
        ],
        filters: true,
        trimDropdown: false,
        stretchH: 'all',
        fixedColumnsLeft: this.fixedColumnsLeft,
        manualColumnResize: true,
        manualRowResize: true,
        columnSorting: true,
        sortIndicator: true,
        minSpareRows: 0,
        allowInsertColumn: false,
        allowRemoveColumn: false,
        allowInsertRow: false,
        allowRemoveRow: false,
        currentRowClassName: 'currentRow',
        currentColClassName: 'currentColumn',
        afterValidate: this.afterValidate,
        beforeChange: this.beforeChange,
        autoRowSize: true,
        viewportRowRenderingOffset: 100,
        hiddenColumns: {
          indicators: true
        }
      }
    }
  },
  methods: {
    /**
     * After validation hook. Fired before cell is rendered,
     * but only if a validator function has been defined.
     *
     * Adds 'isValid' prop to cell metadata which
     * can be used in custom render functions.
     *
     * @param {Boolean} isValid - true if valid, else false
     * @param {*} value - cell value
     * @param {Number} row - visual row index
     * @param {String|Number} prop - property name (accessor) or visual column index
     * @param {String} [source] - source of hook call
     */
    afterValidate (isValid, value, row, prop, source) {
      const table = this.$refs.spreadsheet.hotInstance
      const col = table.propToCol(prop)
      table.setCellMeta(row, col, 'isValid', isValid)
    },
    /**
     * Before change hook. Fired before cell is rendered.
     *
     * Adds 'htDirty' class name to identify changed cells.
     *
     * @param {Array} changes - 2D array containing information about the edited cells
     * @param {String} [source] - identifies source hook of call
     */
    beforeChange (changes, source) {
      if (source === 'loadData') {
        return
      }
      /* eslint-disable no-unused-vars */
      let index = -1
      for (const [row, prop, oldValue, newValue] of changes) {
        index++

        if (oldValue === newValue) {
          continue
        }
        const table = this.$refs.spreadsheet.hotInstance
        const col = table.propToCol(prop)

        // Fix issue with cleared text cells being null instead of the empty string
        const cellDataType = table.getDataType(row, col, row, col)
        if (['text', 'rf.text'].includes(cellDataType) && newValue === null) {
          changes[index][3] = ''
          continue
        }

        // Add class name to identify changed cells
        table.setCellMeta(row, col, 'className', 'htDirty')
      }
    }
  }
}
</script>

<style>
#spreadsheet td.htDirty {
  background-color: palegreen;
}
#spreadsheet td.htInvalid {
  /* Normally we try to avoid using !important,
  but ht uses it so we don't have much choice */
  background-color: #f56c6c !important;
}
#spreadsheet td.search-highlight {
  color: #f8f8ff;
  background-color: #4fa7ff;
}
#spreadsheet span.htHeaderTooltip {
  cursor: help;
}
</style>
