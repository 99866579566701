<template>
  <div id="property-spreadsheet">
    <div class="title">
      <h3>
        Property Spreadsheet for {{ company.human_name }}
      </h3>
      <el-alert
        title="This spreadsheet view is no longer supported. Use at your own risk."
        type="warning"
        show-icon
        :closable="false"
      />
    </div>

    <property-spreadsheet-table :company="company" />
  </div>
</template>

<script>
import PropertySpreadsheetTable from './_components/PropertySpreadsheetTable'

export default {
  name: 'PropertySpreadsheet',
  components: {
    'property-spreadsheet-table': PropertySpreadsheetTable
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.el-alert {
  margin-bottom: 2em
}
</style>
