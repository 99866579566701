/**
 * handsontable.js
 *
 * This file contains all the handsontable-related settings
 * used by the admin-portal app. All custom methods should
 * be registered here so that they can be accessed by table
 * instances.
 *
 * Also defined here are named collections of settings which
 * can be imported and used by the Spreadsheet.vue component.
 */
import validators from './validators'
import store from '@/store'
import { htConstants as constants } from '@/utils/constants'

import './register' // trigger all registration hooks

/**
 * Helper function to format the given regions as a sorted list of names.
 *
 * @param {Array} regions
 * @returns {Array}
 */
const getRegionNames = regions => {
  return [null].concat(regions.map(r => r.name).sort((a, b) => a.localeCompare(b)))
}

/**
 * Helper function to add a tooltip to the given header.
 *
 * @param {String} header
 * @param {String} tooltip
 * @returns {String} - html string
 */
const getHeaderWithTooltip = (header, tooltip) => {
  return `
    <span title="${tooltip}" class="htHeaderTooltip">
      ${header}
    </span>
  `
}

/**
 * Common dropdown options.
 *
 * For use with Handsontable 'dropdown' cell type.
 */
let dropdownOptions = {
  major_regions: [],
  sub_regions: [],
  sub_sub_regions: []
}
// FIXME: store is undefined when file is imported during unit tests
if (store) {
  dropdownOptions = {
    major_regions: getRegionNames(store.state.regions.major_regions),
    sub_regions: getRegionNames(store.state.regions.sub_regions),
    sub_sub_regions: getRegionNames(store.state.regions.sub_sub_regions)
  }
}

/**
 * Preset Handsontable settings.
 */
const presets = {
  general: {
    colHeaders: [
      getHeaderWithTooltip('Property Name', 'Name of this property'),
      getHeaderWithTooltip('Status', 'Property status'),
      getHeaderWithTooltip('Legal Name', 'Legal name for this property'),
      getHeaderWithTooltip('Type', 'Property type'),
      getHeaderWithTooltip('Unit Count', 'The number of units this property maintains'),
      getHeaderWithTooltip('CRM ID', 'Customer relationship management software id'),
      getHeaderWithTooltip('Post Update ID', 'Internal id used for post updater'),
      getHeaderWithTooltip('Notes', 'General notes field'),
      'Modify'
    ],
    columns: [
      { data: 'property.name', renderer: 'rf.link.edit', readOnly: true, colWidths: 250 },
      { data: 'property.status', renderer: 'rf.status', readOnly: true, colWidths: 110 },
      { data: 'property.legal_name', type: 'rf.text', validator: 'rf.propertyName', colWidths: 250 },
      { data: 'property.type', type: 'dropdown', source: constants.propertyTypes, allowInvalid: false, colWidths: 150 },
      { data: 'property.unit_count', type: 'numeric', colWidths: 200 },
      { data: 'property.crm_id', type: 'rf.text', validator: (value, cb) => validators.MaxLengthValidator(value, 100, cb), colWidths: 120 },
      { data: 'property.post_update_id', type: 'rf.text', validator: (value, cb) => validators.MaxLengthValidator(value, 100, cb), colWidths: 200 },
      { data: 'property.notes' },
      { data: 'edit', readOnly: true, renderer: 'rf.button.edit', colWidths: 75 }
    ]
  },

  website: {
    colHeaders: [
      getHeaderWithTooltip('Property Name', 'Name of this property'),
      getHeaderWithTooltip('Status', 'Property status'),
      getHeaderWithTooltip('Website URL', 'Property website URL'),
      getHeaderWithTooltip('Floor Plan URLs', 'Comma-separated list of floor plan URLs'),
      'Modify'
    ],
    columns: [
      { data: 'property.name', renderer: 'rf.link.edit', readOnly: true, colWidths: 250 },
      { data: 'property.status', renderer: 'rf.status', readOnly: true, colWidths: 100 },
      { data: 'property.website_url', type: 'rf.url', colWidths: 500 },
      { data: 'property.floor_plan_urls', type: 'rf.urlList', colWidths: 500 },
      { data: 'edit', readOnly: true, renderer: 'rf.button.edit', colWidths: 75 }
    ]
  },

  craigslistDetails: {
    colHeaders: [
      getHeaderWithTooltip('Property Name', 'Name of this property'),
      getHeaderWithTooltip('Status', 'Property status'),
      getHeaderWithTooltip('Posting Category', 'Craigslist posting category'),
      getHeaderWithTooltip('Region (major)', 'Craigslist major region'),
      getHeaderWithTooltip('Region (sub)', 'Craigslist sub-region'),
      getHeaderWithTooltip('Region (sub-sub)', 'Craigslist sub-sub-region'),
      getHeaderWithTooltip('Email Policy', 'How to display the property email on craigslist ads'),
      'Modify'
    ],
    columns: [
      { data: 'property.name', renderer: 'rf.link.edit', readOnly: true, colWidths: 250 },
      { data: 'property.status', readOnly: true, renderer: 'rf.status', colWidths: 110 },
      { data: 'posting_category', type: 'dropdown', source: constants.postingCategory, allowInvalid: false, colWidths: 150 },
      { data: 'major_region', type: 'dropdown', source: dropdownOptions.major_regions, allowInvalid: false, colWidths: 150 },
      { data: 'sub_region', type: 'dropdown', source: dropdownOptions.sub_regions, allowInvalid: false, colWidths: 180 },
      { data: 'sub_sub_region', type: 'dropdown', source: dropdownOptions.sub_sub_regions, allowInvalid: false, colWidths: 180 },
      { data: 'email_policy', type: 'dropdown', source: constants.emailPolicy, allowInvalid: false, colWidths: 130 },
      { data: 'edit', readOnly: true, renderer: 'rf.button.edit', colWidths: 75 }
    ]
  },

  adDetails: {
    colHeaders: [
      getHeaderWithTooltip('Property Name', 'Name of this property'),
      getHeaderWithTooltip('Status', 'Property status'),
      getHeaderWithTooltip('Dog Policy', 'Whether or not this property allows dogs'),
      getHeaderWithTooltip('Cat Policy', 'Whether or not this property allows cats'),
      getHeaderWithTooltip('Smoking Policy', 'Whether or not this property allows smoking'),
      getHeaderWithTooltip('Wheelchair Access', 'Whether or not this property has wheelchair access'),
      getHeaderWithTooltip('Furnished', 'Whether or not this property is furnished'),
      getHeaderWithTooltip('Laundry', 'Whether or not this property includes laundry'),
      getHeaderWithTooltip('Parking', 'Whether or not this property includes parking'),
      'Modify'
    ],
    columns: [
      { data: 'property.name', renderer: 'rf.link.edit', readOnly: true, colWidths: 250 },
      { data: 'property.status', readOnly: true, renderer: 'rf.status', colWidths: 110 },
      { data: 'dog_policy', type: 'dropdown', source: constants.dogPolicy, allowInvalid: false, colWidths: 100 },
      { data: 'cat_policy', type: 'dropdown', source: constants.catPolicy, allowInvalid: false, colWidths: 100 },
      { data: 'smoking_policy', type: 'dropdown', source: constants.smokingPolicy, allowInvalid: false, colWidths: 100 },
      { data: 'wheelchair_access', type: 'dropdown', source: constants.wheelchairAccess, allowInvalid: false, colWidths: 100 },
      { data: 'furnished', type: 'dropdown', source: constants.furnished, allowInvalid: false, colWidths: 100 },
      { data: 'laundry', type: 'dropdown', source: constants.laundry, allowInvalid: false, colWidths: 150 },
      { data: 'parking', type: 'dropdown', source: constants.parking, allowInvalid: false, colWidths: 150 },
      { data: 'edit', readOnly: true, renderer: 'rf.button.edit', colWidths: 75 }
    ]
  },

  adContent: {
    colHeaders: [
      getHeaderWithTooltip('Property Name', 'Name of this property'),
      getHeaderWithTooltip('Status', 'Property status'),
      getHeaderWithTooltip('Disclaimer', 'Comma-separated list of disclaimers'),
      getHeaderWithTooltip('Location', 'Comma-separated list of nearby location-specific perks'),
      getHeaderWithTooltip('Amenities (property)', 'Comma-separated list of property-specific amenities'),
      getHeaderWithTooltip('Amenities (unit)', 'Comma-separated list of unit-specific amenities'),
      getHeaderWithTooltip('Romance Text', 'Comma-separated list of romance text'),
      getHeaderWithTooltip('Header', 'Comma-separated list of custom headers'),
      getHeaderWithTooltip('Footer', 'Comma-separated list of custom footers'),
      'Modify'
    ],
    columns: [
      { data: 'property.name', renderer: 'rf.link.edit', readOnly: true, colWidths: 250 },
      { data: 'property.status', readOnly: true, renderer: 'rf.status', colWidths: 110 },
      { data: 'disclaimer', type: 'rf.list', colWidths: 200 },
      { data: 'location', type: 'rf.list', colWidths: 200 },
      { data: 'amenities_property', type: 'rf.list', colWidths: 200 },
      { data: 'amenities_unit', type: 'rf.list', colWidths: 200 },
      { data: 'romance_text', type: 'rf.list', colWidths: 200 },
      { data: 'header', type: 'rf.list', colWidths: 200 },
      { data: 'footer', type: 'rf.list', colWidths: 200 },
      { data: 'edit', readOnly: true, renderer: 'rf.button.edit', colWidths: 75 }
    ]
  },

  billing: {
    colHeaders: [
      getHeaderWithTooltip('Property Name', 'Name of this property'),
      getHeaderWithTooltip('Status', 'Property status'),
      getHeaderWithTooltip('Billing Email', 'The billing email, if this property has one'),
      getHeaderWithTooltip('Zoho Customer ID', 'ZOHO Books customer ID'),
      'Billing - Street',
      'Billing - City',
      'Billing - State',
      'Billing - Country',
      'Billing - Postal',
      'Billing - Latitude',
      'Billing - Longitude',
      'Modify'
    ],
    columns: [
      { data: 'property.name', renderer: 'rf.link.edit', readOnly: true, colWidths: 250 },
      { data: 'property.status', readOnly: true, renderer: 'rf.status', colWidths: 110 },
      { data: 'property.billing_email', type: 'rf.text', validator: 'rf.email', allowInvalid: true, colWidths: 200 },
      { data: 'property.zoho_customer_id', type: 'numeric', validator: 'numeric', allowInvalid: false, colWidths: 200 },
      { data: 'property.billing_address.street', type: 'rf.text', validator: 'rf.address.street', colWidths: 250 },
      { data: 'property.billing_address.city', type: 'rf.text', validator: 'rf.address.city', colWidths: 120 },
      { data: 'property.billing_address.state', type: 'rf.text', validator: 'rf.address.state', colWidths: 130 },
      { data: 'property.billing_address.country', type: 'rf.text', validator: 'rf.address.country', colWidths: 150 },
      { data: 'property.billing_address.postal', type: 'rf.text', validator: 'rf.address.postal', colWidths: 150 },
      { data: 'property.billing_address.latitude', type: 'numeric', validator: 'numeric', allowInvalid: false, colWidths: 200 },
      { data: 'property.billing_address.longitude', type: 'numeric', validator: 'numeric', allowInvalid: false, colWidths: 200 },
      { data: 'edit', readOnly: true, renderer: 'rf.button.edit', colWidths: 75 }
    ]
  },

  contact: {
    colHeaders: [
      getHeaderWithTooltip('Property Name', 'Name of this property'),
      getHeaderWithTooltip('Status', 'Property status'),
      getHeaderWithTooltip('Property Email', 'Primary email for this property'),
      getHeaderWithTooltip('Lead Email', 'Lead email for this property'),
      getHeaderWithTooltip('Property Phone', 'Primary phone number for this property'),
      getHeaderWithTooltip('Lead Phone', 'Lead phone number for this property'),
      getHeaderWithTooltip('CallRail Phone', 'CallRail tracking number'),
      'Property - Street',
      'Property - City',
      'Property - State',
      'Property - Country',
      'Property - Postal',
      'Property - Latitude',
      'Property - Longitude',
      'Modify'
    ],
    columns: [
      { data: 'property.name', renderer: 'rf.link.edit', readOnly: true, colWidths: 250 },
      { data: 'property.status', readOnly: true, renderer: 'rf.status', colWidths: 110 },
      { data: 'property.email_contact', type: 'rf.text', validator: 'rf.email', allowInvalid: true, colWidths: 200 },
      { data: 'property.email_leasing', type: 'rf.text', validator: 'rf.email', allowInvalid: true, colWidths: 200 },
      { data: 'property.phone_contact', type: 'rf.text', validator: 'rf.phone', colWidths: 120 },
      { data: 'property.phone_leasing', type: 'rf.text', validator: 'rf.phone', colWidths: 120 },
      { data: 'property.phone_callrail', type: 'rf.text', validator: 'rf.phone', colWidths: 120 },
      { data: 'property.property_address.street', type: 'rf.text', validator: 'rf.address.street', colWidths: 250 },
      { data: 'property.property_address.city', type: 'rf.text', validator: 'rf.address.city', colWidths: 120 },
      { data: 'property.property_address.state', type: 'rf.text', validator: 'rf.address.state', colWidths: 100 },
      { data: 'property.property_address.country', type: 'rf.text', validator: 'rf.address.country', colWidths: 150 },
      { data: 'property.property_address.postal', type: 'rf.text', validator: 'rf.address.postal', colWidths: 150 },
      { data: 'property.property_address.latitude', type: 'numeric', validator: 'numeric', allowInvalid: false, colWidths: 200 },
      { data: 'property.property_address.longitude', type: 'numeric', validator: 'numeric', allowInvalid: false, colWitdths: 200 },
      { data: 'edit', readOnly: true, renderer: 'rf.button.edit', colWidths: 75 }
    ]
  },

  emailHandling: {
    colHeaders: [
      getHeaderWithTooltip('Property Name', 'Name of this property'),
      getHeaderWithTooltip('Status', 'Property status'),
      getHeaderWithTooltip('Email Delivery Lead', 'Internal field used for email handling'),
      getHeaderWithTooltip('Email Delivery Lead Copy', 'Internal field used for email handling'),
      getHeaderWithTooltip('Email Delivery Property', 'Internal field used for email handling'),
      'Modify'
    ],
    columns: [
      { data: 'property.name', renderer: 'rf.link.edit', readOnly: true, colWidths: 250 },
      { data: 'property.status', readOnly: true, renderer: 'rf.status', colWidths: 110 },
      { data: 'property.email_delivery_lead', type: 'rf.text', validator: 'rf.email', colWidths: 200 },
      { data: 'property.email_delivery_lead_copy', type: 'rf.text', validator: 'rf.email', colWidths: 200 },
      { data: 'property.email_delivery_property', type: 'rf.text', validator: 'rf.email', colWidths: 200 },
      { data: 'edit', readOnly: true, renderer: 'rf.button.edit', colWidths: 75 }
    ]
  },

  extra: {
    colHeaders: [
      getHeaderWithTooltip('Property Name', 'Name of this property'),
      getHeaderWithTooltip('Status', 'Property status'),
      getHeaderWithTooltip('Extra - Property', 'Custom property fields'),
      getHeaderWithTooltip('Extra - Posting', 'Custom posting fields'),
      'Modify'
    ],
    columns: [
      { data: 'property.name', renderer: 'rf.link.edit', readOnly: true, colWidths: 250 },
      { data: 'property.status', readOnly: true, renderer: 'rf.status', colWidths: 110 },
      { data: 'property.extra', type: 'rf.json' },
      { data: 'extra', type: 'rf.json' },
      { data: 'edit', readOnly: true, renderer: 'rf.button.edit', colWidths: 75 }
    ]
  },

  craigslistAccounts: {
    colHeaders: [
      getHeaderWithTooltip('Email', 'The email address for this account'),
      getHeaderWithTooltip('Password', 'The password for this account'),
      getHeaderWithTooltip('Region', 'The craigslist major region for this account'),
      getHeaderWithTooltip('Status', 'The current status of this account'),
      'Proxy IP',
      'Proxy Port',
      'Proxy Username',
      'Proxy Password',
      'Notes'
    ],
    columns: [
      { data: 'email', type: 'rf.text', validator: 'rf.email', colWidths: 230 },
      { data: 'password', validator: (value, cb) => validators.MaxLengthValidator(value, 100, cb), colWidths: 160 },
      { data: 'region', type: 'dropdown', source: dropdownOptions.major_regions, allowInvalid: false, colWidths: 150 },
      { data: 'status', type: 'dropdown', source: constants.craigslistAccountStatus, allowInvalid: false, colWidths: 100 },
      { data: 'proxy_ip', validator: 'rf.ip', colWidths: 130 },
      { data: 'proxy_port', type: 'numeric', validator: (value, cb) => validators.MaxValueValidator(value, 65535, cb), colWidths: 110 },
      { data: 'proxy_username', validator: (value, cb) => validators.MaxLengthValidator(value, 128, cb), colWidths: 160 },
      { data: 'proxy_password', validator: (value, cb) => validators.MaxLengthValidator(value, 128, cb), colWidths: 160 },
      { data: 'notes', type: 'rf.text', colWidths: 250 }
    ]
  }
}

/**
 * Handsontable configurations.
 */
const TABLES = {
  'general': {
    label: 'General',
    settings: presets.general
  },
  'website': {
    label: 'Property Website',
    settings: presets.website
  },
  'craigslist-details': {
    label: 'Craigslist Site',
    settings: presets.craigslistDetails
  },
  'ad-details': {
    label: 'Ad Details',
    settings: presets.adDetails
  },
  'ad-content': {
    label: 'Ad Content',
    settings: presets.adContent
  },
  'billing': {
    label: 'Billing',
    settings: presets.billing
  },
  'contact': {
    label: 'Contact',
    settings: presets.contact
  },
  'email-handling': {
    label: 'Email Handling',
    settings: presets.emailHandling
  },
  'extra': {
    label: 'Custom Fields',
    settings: presets.extra
  },
  'craigslist-accounts': {
    label: 'Craigslist Accounts',
    settings: presets.craigslistAccounts
  }
}

const handsontable = {
  /**
   * Get the handsontable settings for the given table config.
   *
   * @param {String} type - the table key
   * @returns {Object}
   */
  getTableSettings (type) {
    const table = TABLES[type]
    if (!table) {
      return {
        colHeaders: [],
        columns: []
      }
    }
    return table.settings
  },
  /**
   * Get a list of all the preset handsontable configurations
   * for the property view.
   *
   * @returns {Object}
   */
  getPropertyTablePresets () {
    const notAllowed = ['craigslist-accounts']
    return Object.keys(TABLES)
      .filter(key => !notAllowed.includes(key))
      .map(key => ({
        value: key,
        label: TABLES[key].label
      }))
  }
}

export default handsontable
