/**
 * Custom cell editors.
 *
 * Can either extend existing editors, or create a
 * new one from scratch by inheriting from BaseEditor.
 *
 * https://handsontable.com/docs/7.1.0/tutorial-cell-editor.html
 */
import Handsontable from 'handsontable'
import { isString, csvStringToArray } from '@/utils'

/**
 * JSONEditor
 *
 * Auto-formats JSON content when possible.
 */
class JSONEditor extends Handsontable.editors.TextEditor {
  getValue () {
    try {
      return JSON.stringify(JSON.parse(this.TEXTAREA.value), null, 2)
    } catch (err) {
      return this.TEXTAREA.value
    }
  }

  setValue (newValue) {
    try {
      this.TEXTAREA.value = JSON.stringify(JSON.parse(newValue), null, 2)
    } catch (err) {
      this.TEXTAREA.value = newValue
    }
  }
}

/**
 * ListEditor
 *
 * Formats an array as a csv string so it can be modified in the spreadsheet.
 */
class ListEditor extends Handsontable.editors.TextEditor {
  getValue () {
    const value = this.TEXTAREA.value

    if (isString(value)) {
      return csvStringToArray(value)
    }
    return value
  }

  setValue (newValue) {
    if (isString(newValue)) {
      this.TEXTAREA.value = csvStringToArray(newValue)
    } else {
      this.TEXTAREA.value = newValue
    }
  }
}

export default {
  JSONEditor,
  ListEditor
}
