/**
 * IIFE to trigger registration hooks for all custom
 * cellTypes, validators, renderers, and editors.
 */

import Handsontable from 'handsontable'

import editors from './editors'
import renderers from './renderers'
import validators from './validators'
import cellTypes from './cellTypes'

(function (Handsontable) {
  try {
    // Editors
    Handsontable.editors.registerEditor('rf.json', editors.JSONEditor)
    Handsontable.editors.registerEditor('rf.list', editors.ListEditor)

    // Renderers
    Handsontable.renderers.registerRenderer('rf.text', renderers.TextRenderer)
    Handsontable.renderers.registerRenderer('rf.json', renderers.JSONRenderer)
    Handsontable.renderers.registerRenderer('rf.url', renderers.URLRenderer)
    Handsontable.renderers.registerRenderer('rf.urlList', renderers.URLListRenderer)
    Handsontable.renderers.registerRenderer('rf.status', renderers.StatusRenderer)
    Handsontable.renderers.registerRenderer('rf.button.edit', renderers.EditButtonRenderer)
    Handsontable.renderers.registerRenderer('rf.link.edit', renderers.EditLinkRenderer)

    // Validators
    Handsontable.validators.registerValidator('rf.email', validators.EmailValidator)
    Handsontable.validators.registerValidator('rf.url', validators.URLValidator)
    Handsontable.validators.registerValidator('rf.urlList', validators.URLListValidator)
    Handsontable.validators.registerValidator('rf.json', validators.JSONValidator)
    Handsontable.validators.registerValidator('rf.ip', validators.IPAddressValidator)
    Handsontable.validators.registerValidator('rf.propertyName', (value, cb) => validators.MaxLengthValidator(value, 128, cb))
    Handsontable.validators.registerValidator('rf.phone', (value, cb) => validators.MaxLengthValidator(value, 31, cb))
    Handsontable.validators.registerValidator('rf.address.street', (value, cb) => validators.MaxLengthValidator(value, 300, cb))
    Handsontable.validators.registerValidator('rf.address.city', (value, cb) => validators.MaxLengthValidator(value, 100, cb))
    Handsontable.validators.registerValidator('rf.address.state', (value, cb) => validators.MaxLengthValidator(value, 2, cb))
    Handsontable.validators.registerValidator('rf.address.country', (value, cb) => validators.MaxLengthValidator(value, 100, cb))
    Handsontable.validators.registerValidator('rf.address.postal', (value, cb) => validators.MaxLengthValidator(value, 10, cb))

    // Cell Types
    Handsontable.cellTypes.registerCellType('rf.text', cellTypes.text)
    Handsontable.cellTypes.registerCellType('rf.json', cellTypes.json)
    Handsontable.cellTypes.registerCellType('rf.list', cellTypes.list)
    Handsontable.cellTypes.registerCellType('rf.url', cellTypes.url)
    Handsontable.cellTypes.registerCellType('rf.urlList', cellTypes.urlList)
  } catch (err) {
    console.warn('Handsontable alias registration failed, are you sure all the objects being registered exist?')
    console.error(err.stack)
  }
})(Handsontable)
